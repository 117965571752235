import { ServiceBase } from '@sgf/go-api-services';

class MiscellaneousApi extends ServiceBase {
  _origin = 'miscellaneousApi';

  async getMiscellaneous(context, personId) {
    return await this.fetch(
      `/persons/${personId}/miscellaneous`,
      {
        method: 'GET',
      },
      context,
      'getMiscellaneous',
      'Hämtar...'
    );
  }
  async saveMiscellaneous(context, payload, personId) {
    let endpoint = `/persons/${personId}/miscellaneous`;
    return await this.fetch(
      endpoint,
      {
        method: 'PUT',
        body: JSON.stringify(payload),
      },
      context,
      'saveMiscellaneous',
      'Sparar...'
    );
  }
  async deletePerson(context, personId) {
    return await this.fetch(
      `/persons/${personId}`,
      {
        method: 'DELETE',
      },
      context,
      'deletePerson',
      'Tar bort...'
    );
  }
}

export default MiscellaneousApi;
