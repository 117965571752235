import { ServiceBase, apiTypes } from '@sgf/go-api-services';

function setLoader(context, isActive, correlationId, message = 'Sparar...') {
  context.dispatch(
    apiTypes.actions.SET_LOADER,
    {
      isActive: isActive,
      correlationId: correlationId,
      message: message,
      origin: 'feesApi',
    },
    {
      root: true,
    }
  );
}

class FeesApi extends ServiceBase {
  _origin = 'feesApi';

  _getPersonFees(baseUrl, personId) {
    return `${baseUrl}/persons/${personId}/fees`;
  }

  _getPersonFeeHistory(baseUrl, personId) {
    return `${baseUrl}/persons/${personId}/fees/history`;
  }

  _getLockers(baseUrl, lockerSeriesId) {
    return `${baseUrl}/organizations/lockerseries/${lockerSeriesId}/lockers`;
  }

  _getLockerSeries(baseUrl) {
    return `${baseUrl}/organizations/lockerseries`;
  }

  _saveFees(baseUrl, personId) {
    return `${baseUrl}/persons/${personId}/fees`;
  }

  async getPersonFees(context, personId) {
    let correlationId = 'getPersonFees';
    let options = this._getOptions(context);
    let endpoint = this._getPersonFees(options.baseUrl, personId);
    try {
      setLoader(context, true, correlationId, 'Hämtar...');
      var response = await fetch(endpoint, {
        ...options,
        method: 'GET',
      });
      setLoader(context, false, correlationId, null);
      return this._processResponse(context, response, correlationId);
    } catch (e) {
      setLoader(context, false, correlationId, null);
      return this._processError(context, correlationId, 'Felaktigt anrop', 400);
    }
  }

  async getLockers(context, id) {
    let endpoint = `/organizations/lockerseries/${id}/lockers`;
    return this.fetch(
      endpoint,
      {
        method: 'GET',
      },
      context,
      'getLockers',
      'Hämtar...',
      true
    );
  }

  async getLockerSeries(context) {
    let correlationId = 'getLockerSeries';
    let options = this._getOptions(context);
    let endpoint = this._getLockerSeries(options.baseUrl);
    try {
      setLoader(context, true, correlationId, 'Hämtar...');
      var response = await fetch(endpoint, {
        ...options,
        method: 'GET',
      });
      setLoader(context, false, correlationId, null);
      return this._processResponse(context, response, correlationId);
    } catch (e) {
      setLoader(context, false, correlationId, null);
      return this._processError(context, correlationId, 'Felaktigt anrop', 400);
    }
  }

  async getPersonFeeHistory(context, personId) {
    let correlationId = 'getPersonFeeHistory';
    let options = this._getOptions(context);
    let endpoint = this._getPersonFeeHistory(options.baseUrl, personId);
    try {
      setLoader(context, true, correlationId, 'Hämtar...');
      var response = await fetch(endpoint, {
        ...options,
        method: 'GET',
      });
      setLoader(context, false, correlationId, null);
      return this._processResponse(context, response, correlationId);
    } catch (e) {
      setLoader(context, false, correlationId, null);
      return this._processError(context, correlationId, 'Felaktigt anrop', 400);
    }
  }

  async saveFees(context, payload) {
    let correlationId = 'saveFees';
    let options = this._getOptions(context);
    let endpoint = this._saveFees(options.baseUrl, payload.personId);
    try {
      setLoader(context, true, correlationId, 'Sparar...');
      var response = await fetch(endpoint, {
        ...options,
        method: 'POST',
        body: JSON.stringify(payload),
      });
      setLoader(context, false, correlationId, null);
      return this._processResponse(context, response, correlationId);
    } catch (e) {
      setLoader(context, false, correlationId, null);
      return this._processError(context, correlationId, 'Felaktigt anrop', 400);
    }
  }

  async getCapitalArticles(context) {
    let endpoint = `/organizations/capitalArticles`;
    return this.fetch(
      endpoint,
      {
        method: 'GET',
      },
      context,
      'getCapitalArticles',
      'Hämtar...',
      true
    );
  }

  async getCapitalSeries(context, id) {
    let endpoint = `/organizations/capitalInvestments/${id}`;
    return this.fetch(
      endpoint,
      {
        method: 'GET',
      },
      context,
      'getCapitalSeries',
      'Hämtar...',
      true
    );
  }

  async getCompanies(context, searchPhrase) {
    let endpoint = searchPhrase == '' ? `/companies` : `/companies?searchPhrase=${searchPhrase}`;
    return this.fetch(
      endpoint,
      {
        method: 'GET',
      },
      context,
      'getCompanies',
      'Söker...',
      true
    );
  }

  async saveEditCapital(context, payload) {
    let requestPayload = {
      fees: payload.fees,
      personId: payload.personId,
    };
    let endpoint = payload.isCompany ? `/persons/${payload.personId}/fees?isCompany=${payload.isCompany}` : `/persons/${payload.personId}/fees`;

    return this.fetch(
      endpoint,
      {
        method: 'POST',
        body: JSON.stringify(requestPayload),
      },
      context,
      'saveEditCapital',
      'Sparar...',
      true
    );
  }
}

export default FeesApi;
