import moment from 'moment';
export default {
  fees: undefined,
  isLockerModalActive: false,
  isFeeModalActive: false,
  selectedLockers: {},
  editLocker: {},
  feesRemoved: [],
  filter: {
    onlyUnrented: true,
    numberFrom: null,
    numberTo: null,
  },
  selectedDueDate: moment().add(20, 'days').format('YYYY-MM-DD'),
  removeFee: undefined,
  selectedLockerSeriesId: undefined,
  lockerSeries: undefined,
  feeHistory: undefined,
  feesHash: undefined,
  feesUpdatedHash: undefined,
  feeHistoryHash: undefined,
  feeHistoryUpdatedHash: undefined,
  hasNoLockers: false,

  isCapitalModalActive: false,
  isNestedCapitalModalActive: false,
  isNestedSearchModalActive: false,
  isCurrentOwner: false,
  returnToClub: false,
  selectedCurrentOwnerType: undefined,
  isCurrentUserChanged: false,
  capitalArticles: undefined,
  selectedCapitalArticleId: undefined,
  selectedCapitalArticleType: undefined,
  selectedSearchType: undefined,
  searchPartner: undefined,
  partnersSearchResult: undefined,
  selectedCapitalSeries: {},
  selectedCapitalSerieId: undefined,
  capitalFilter: {
    onlyForSale: false,
    numberFrom: null,
    numberTo: null,
  },
  editCapital: {},
  capitalHash: undefined,
};
