import moment from 'moment';
export default {
  createCapital(payload) {
    return {
      ...payload,
      currentOwner: {
        ...payload.currentOwner,
        created: moment().format('YYYY-MM-DD'),
        isForSale: payload.isForSale,
      },
      uploaded: moment().format('YYYY-MM-DD'),
      invoiceHistory: [],
      paymentHistory: [],
      status: '',
      removable: true,
      isActive: false,
      isAdded: true,
    };
  },
  addPersonOrPartner(payload) {
    return {
      ...payload,
      isForSale: true,
      isEditingOwner: true,
      created: moment().format('YYYY-MM-DD'),
    };
  },
};
