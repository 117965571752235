import { _types } from './types';
import InvoiceApi from '../api/invoiceApi';
import { invoiceTypeConstants } from '../constants';
const invoiceApi = new InvoiceApi();

export default {
  [_types.actions.INITIALIZE]: (context) => {
    context.commit(_types.mutations.SET_FIELD, { field: 'golfId', value: context.rootState.personOverview.details.golfId });
    context.dispatch(_types.actions.FETCH_SETTINGS);
  },

  [_types.actions.INITIALIZE_BANKGIRO]: (context) => {
    context.commit(_types.mutations.SET_FIELD, { field: 'golfId', value: context.rootState.personOverview.details.golfId });
    context.dispatch(_types.actions.FETCH_SELLING_ORGS);
  },

  [_types.actions.SET_FIELD]: (context, fieldValue) => {
    context.commit(_types.mutations.SET_FIELD, fieldValue);
    if (fieldValue.field === 'selectedYear') {
      context.commit(_types.mutations.SET_FIELD, { field: 'excludedEngagementIds', value: [] });
      if (context.state.selectedInvoiceType === invoiceTypeConstants.BANKGIRO) context.dispatch(_types.actions.POST_BANKGIRO_JOB);
      else context.dispatch(_types.actions.SUBMIT);
    }
    if (fieldValue.field === 'selectedSellingOrganization' && context.state.selectedInvoiceType === invoiceTypeConstants.BANKGIRO) {
      if (fieldValue.value !== null) {
        context.commit(_types.mutations.SET_FIELD, { field: 'inProgress', value: true });
        context.dispatch(_types.actions.POST_BANKGIRO_JOB);
      } else {
        context.commit(_types.mutations.SET_FIELD, { field: 'jobResult', value: undefined });
      }
    }
  },

  [_types.actions.TOGGLE_ENGAGEMENT_ID]: (context, object) => {
    context.commit(_types.mutations.TOGGLE_ENGAGEMENT_ID, object);
    context.commit(_types.mutations.TOGGLE_ORGANIZATION_SELLING_ID, object);
  },

  [_types.actions.SUBMIT]: async (context, isPreview = true) => {
    context.commit(_types.mutations.SET_FIELD, { field: 'jobResult', value: undefined });
    context.commit(_types.mutations.SET_FIELD, { field: 'isPreview', value: isPreview });
    context.commit(_types.mutations.SET_FIELD, { field: 'jobId' });
    context.commit(_types.mutations.SET_FIELD, { field: 'inProgress', value: true });
    let { golfId, excludedEngagementIds, selectedSellingOrganization, selectedYear } = context.state;
    let payload = {
      year: selectedYear,
      preview: isPreview,
      golfId: golfId,
      excludedEngagementIds: excludedEngagementIds,
      selectedSellingOrganization: selectedSellingOrganization,
      fullName: context.rootState.personOverview.details.firstname + ' ' + context.rootState.personOverview.details.lastname,
    };
    let jobId = await invoiceApi.getJobId(context, payload);
    context.commit(_types.mutations.SET_FIELD, { field: 'jobId', value: jobId });
    context.commit(_types.mutations.SET_FIELD, { field: 'inProgress', value: true });
    context.dispatch(_types.actions.FETCH_JOB_RESULTS);
  },

  [_types.actions.FETCH_JOB_RESULTS]: async (context) => {
    let { jobId } = context.state;
    if (!jobId) {
      return;
    }
    let result = await invoiceApi.getJobResults(context, jobId);
    context.commit(_types.mutations.SET_FIELD, { field: 'jobResult', value: result });
    let engagmentIds = result.invoiceRecipients.reduce((pv, cv) => {
      cv.invoiceRows.forEach((ir) => {
        pv.push(ir.engagmentId);
      });
      return pv;
    }, []);
    context.commit(_types.mutations.SET_FIELD, { field: 'excludedEngagementIds', value: engagmentIds });
    if (result.progressPercentage < 100 && (!result.error || result.error.length === 0)) {
      setTimeout(() => {
        context.dispatch(_types.actions.FETCH_JOB_RESULTS);
      }, 500);
    } else {
      context.commit(_types.mutations.SET_FIELD, { field: 'inProgress', value: false });
    }
  },

  [_types.actions.CLEANUP]: (context) => {
    context.commit(_types.mutations.CLEANUP);
  },

  [_types.actions.FETCH_SELLING_ORGS]: async (context) => {
    context.commit(_types.mutations.SET_FIELD, { field: 'inProgress', value: true });

    let result = await invoiceApi.getSellingOrganizations(context);
    context.commit(_types.mutations.SET_FIELD, { field: 'sellingOrganizations', value: result.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1)) });
    context.commit(_types.mutations.SET_FIELD, { field: 'inProgress', value: false });
  },

  [_types.actions.POST_BANKGIRO_JOB]: async (context, isPreview = true) => {
    let previousJobResult = context.state.jobResult;
    context.commit(_types.mutations.SET_FIELD, { field: 'jobResult', value: undefined });
    context.commit(_types.mutations.SET_FIELD, { field: 'isPreview', value: isPreview });
    context.commit(_types.mutations.SET_FIELD, { field: 'inProgress', value: true });

    let { golfId, excludedEngagementIds, selectedSellingOrganization, selectedYear, includeFamilyMemberFees } = context.state;

    let payload = {
      year: selectedYear,
      preview: isPreview,
      golfId: golfId,
      excludedEngagementIds: isPreview ? [] : excludedEngagementIds,
      selectedSellingOrganization: selectedSellingOrganization,
      fullName: context.rootState.personOverview.details.firstname + ' ' + context.rootState.personOverview.details.lastname,
      includeFamilyMemberFees: includeFamilyMemberFees,
    };

    try {
      let result = await invoiceApi.postBankgiroJobResults(context, payload);
      context.commit(_types.mutations.SET_FIELD, { field: 'jobResult', value: result });
    } catch (err) {
      console.log('Error fetching bankgiro job.'); // eslint-disable-line no-console
      context.commit(_types.mutations.SET_FIELD, { field: 'isPreview', value: true });
      context.commit(_types.mutations.SET_FIELD, { field: 'jobResult', value: previousJobResult });
    }
    context.commit(_types.mutations.SET_FIELD, { field: 'inProgress', value: false });
  },

  [_types.actions.FETCH_SETTINGS]: async (context) => {
    context.commit(_types.mutations.SET_FIELD, { field: 'inProgress', value: true });
    let result = await invoiceApi.getSettings(context);
    context.commit(_types.mutations.SET_FIELD, { field: 'selectedYear', value: result.invoiceYear });
    context.dispatch(_types.actions.SUBMIT);
    context.commit(_types.mutations.SET_FIELD, { field: 'inProgress', value: false });
  },
};
