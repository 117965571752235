import { _types } from './types';
import { hashHelper } from '@sgf/go-utilities';
import { personOverviewTypes } from '@sgf/go-person-overview';
import router from '../../../routes/router';

import MiscellaneousApi from '../api/miscellaneousApi';

const miscellaneousApi = new MiscellaneousApi();

export default {
  [_types.actions.INITIALIZE]: (context, personId) => {
    context.dispatch(_types.actions.CLEAR);
    context.dispatch(_types.actions.FETCH_PERSON, personId);
  },
  [_types.actions.CLEAR]: (context) => {
    context.commit(_types.mutations.CLEAR);
  },
  [_types.actions.FETCH_PERSON]: async (context, personId) => {
    let person = await miscellaneousApi.getMiscellaneous(context, personId);
    if (!person) {
      return;
    }
    context.commit(_types.mutations.SET_PERSON, person);
    context.commit(_types.mutations.SET_GOLF_CAR_PERMISSION_HASH, {
      sgfDates: hashHelper.hashObject(person.golfCarPermission.sgfValidFrom, person.golfCarPermission.sgfValidTo),
      clubDates: hashHelper.hashObject(person.golfCarPermission.clubValidFrom, person.golfCarPermission.clubValidTo),
    });
    context.commit(_types.mutations.SET_HASH, hashHelper.hashObject(person));
  },
  [_types.actions.DELETE_PERSON]: async (context, personId) => {
    context.dispatch(_types.actions.SET_PERSON_TO_DELETE, undefined);
    let result = await miscellaneousApi.deletePerson(context, personId);
    if (result.length > 0) {
      context.commit(_types.mutations.SET_DELETE_PERSON_INFORMATION, result);
      return;
    } else {
      router.replace({
        name: 'start',
      });
    }
  },
  [_types.actions.SET_FIELD]: (context, fieldValue) => {
    context.commit(_types.mutations.SET_FIELD, fieldValue);
  },
  [_types.actions.SET_LICENS_FIELD]: (context, payload) => {
    context.commit(_types.mutations.SET_LICENS_FIELD, payload);
  },
  [_types.actions.SET_GOLF_CAR_PERMISSION_DATE]: (context, payload) => {
    context.commit(_types.mutations.SET_GOLF_CAR_PERMISSION_DATE, payload);
  },
  [_types.actions.SUBMIT_MISCELLANEOUS]: (context) => {
    if (!context.getters[_types.getters.HAS_PLAYER_CHANGED]) {
      return;
    }
    context.commit(_types.mutations.SET_HAS_SUBMITED, true);
    let validations = context.getters[_types.getters.GET_VALIDATIONS];
    if (Object.values(validations).length === 0) {
      context.dispatch(_types.actions.SAVE_MISCELLANEOUS);
      context.commit(_types.mutations.SET_HAS_SUBMITED, false);
    }
  },
  [_types.actions.SAVE_MISCELLANEOUS]: async (context) => {
    let player = context.state.player;
    await miscellaneousApi.saveMiscellaneous(context, player, player.playerId);
    context.dispatch(_types.actions.INITIALIZE, player.playerId);
    context.dispatch(personOverviewTypes.actions.REFETCH_PERSON, player.playerId, {
      root: true,
    });
  },
  [_types.actions.SET_PERSON_TO_DELETE]: (context, pid) => {
    context.commit(_types.mutations.SET_PERSON_TO_DELETE, pid);
  },
  [_types.actions.CLOSE_PERSON_RESULT]: (context) => {
    context.commit(_types.mutations.SET_DELETE_PERSON_INFORMATION, undefined);
  },
};
