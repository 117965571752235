import { ServiceBase } from '@sgf/go-api-services';

class InvoiceApi extends ServiceBase {
  _origin = 'invoiceApi';

  async getSettings(context) {
    return await this.fetch(
      `/invoices/settings`,
      {
        method: 'GET',
      },
      context,
      'getSystems',
      'Hämtar...'
    );
  }

  async getJobId(context, payload) {
    let endpoint = '/invoices';
    return await this.fetch(
      endpoint,
      {
        method: 'POST',
        body: JSON.stringify(payload),
      },
      context,
      'postInvoice',
      'Genererar...',
      true
    );
  }

  async postBankgiroJobResults(context, payload) {
    let endpoint = '/invoices/bankgiro';
    return await this.fetch(
      endpoint,
      {
        method: 'POST',
        body: JSON.stringify(payload)
      },
      context,
      'postBankgiroInvoice',
      'Genererar...'
    )
  }

  async getJobResults(context, jobId) {
    let endpoint = '/invoices/jobs/' + jobId;
    return await this.fetch(
      endpoint,
      {
        method: 'GET',
      },
      context,
      'getInvoice',
      'Hämtar...',
      true
    );
  }

  async getSellingOrganizations(context) {
    return await this.fetch(
      '/invoices/sellingOrganizations',
      {
        method: 'GET'
      },
      context,
      'getSellingOrganizations',
      'Hämtar...'
    )
  }
}

export default InvoiceApi;
