import { authenticationStore, authenticationTypes } from '@sgf/go-authentication';

var authenticationConfiguration = {
  authority: process.env.VUE_APP_IDENTITY_SERVER_AUTHORITY,
  client_id: process.env.VUE_APP_IDENTITY_SERVER_CLIENTID,
  redirect_uri: process.env.VUE_APP_IDENTITY_SERVER_REDIRECT_URI,
  response_type: process.env.VUE_APP_IDENTITY_SERVER_RESPONSE_TYPE,
  scope: process.env.VUE_APP_IDENTITY_SERVER_SCOPE,
  post_logout_redirect_uri: process.env.VUE_APP_IDENTITY_SERVER_LOGOUT_URI,
  identityServerEnabled: process.env.VUE_APP_IDENTITY_SERVER_ENABLED,
  silent_redirect_uri: process.env.VUE_APP_IDENTITY_SERVER_SILENT_URI,
};

authenticationStore.configuration(authenticationConfiguration);

if (process.env.VUE_APP_IS_DEVELOPMENT) {
  authenticationStore.state.user.profile.clubId = '801e53af-cff9-474d-982a-f7c3a8eb6625';
  authenticationStore.state.user.profile.clubName = 'Upsala Golfklubb';
  authenticationStore.state.user.profile.firstName = 'Rickard';
  authenticationStore.state.user.profile.lastName = 'Ahlbom';
  authenticationStore.state.user.profile.golfId = '900420-054';
  authenticationStore.state.user.profile.role = ['PERSON_READ', 'PERSON_DELETE'];
}

export { authenticationTypes };
export default authenticationStore;
