const invoiceTypeConstants = {
  FINQR: 'finqr',
  BANKGIRO: 'bankgiro'
};

const invoiceColumnConstants = {
  FINQR: [
    {
      key: 'articleName',
      header: 'Avgift',
      sort: 'text',
    },
    {
      key: 'sellingOrganizationName',
      header: 'Säljorganisation',
      sort: 'text',
    },
    {
      key: 'invoiceAmount',
      header: 'Belopp',
      sort: 'number',
      width: '160px',
      align: 'right',
    },
    {
      key: 'status',
      header: 'Status',
      sort: 'text',
      width: '160px',
    },
    {
      key: 'createdDate',
      header: 'Upplagd datum',
      sort: 'text',
    },
    {
      key: 'exclude',
      header: 'Ta med',
      width: '100px',
      align: 'center',
    },
  ],
  BANKGIRO: [
    {
      key: 'name',
      header: 'Namn',
      sort: 'text',
    },
    {
      key: 'articleName',
      header: 'Avgiftsnamn',
      sort: 'text',
    },
    {
      key: 'status',
      header: 'Status',
      sort: 'text',
      width: '160px',
    },
    {
      key: 'createdDate',
      header: 'Upplagd datum',
      sort: 'text',
    },
    {
      key: 'engagementValidFrom',
      header: 'Giltig från',
      sort: 'text'
    },
    {
      key: 'engagementValidTo',
      header: 'Giltig till',
      sort: 'text'
    },
    {
      key: 'invoiceAmount',
      header: 'Belopp',
      sort: 'number',
      width: '160px',
      align: 'right',
    },
    {
      key: 'exclude',
      header: 'Ta med',
      width: '100px',
      align: 'center',
    },
  ],
};

export { invoiceTypeConstants, invoiceColumnConstants };