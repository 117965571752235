import moment from 'moment';

export default {
  createFees(fees, selectedDueDate) {
    let result = [];

    fees.forEach((fee) => {
      let selectedFee = {
        id: fee.id,
        articleId: fee.id,
        price: fee.amount,
        name: fee.name,
        isActive: false,
        status: '',
        uploaded: moment().format('YYYY-MM-DD'),
        balance: fee.amount,
        isAdded: true,
        invoiceHistory: [],
        paymentHistory: [],
      };
      if (fee.typeId === '6508d806-ed36-4ccc-8f2b-5e6e1891c11a') {
        selectedFee.dueDate = selectedDueDate;
      }
      result.push(selectedFee);
    });

    return result;
  },
};
