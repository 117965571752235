export default {
  personId: undefined,
  articleId: undefined,
  feeId: undefined,
  gameRights: undefined,
  selectedGameRight: undefined,

  isAddRoundActive: false,
  roundToRemove: undefined,

  feeName: undefined,
  engagementId: undefined,
  validFrom: undefined,
  validTo: undefined,
  description: undefined,
  remainingCredits: undefined,
  usedCredits: undefined,
  totalCredits: undefined,
  usageOccasions: undefined,
};
