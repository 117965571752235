import { _types } from './types';
import FeesApi from '../api/feesApi';
import { hashHelper } from '@sgf/go-utilities';
import capitalHelper from '../helpers/capital-helper';

const feesApi = new FeesApi();

function _setHash(context) {
  let hashedFees = hashHelper.hashObject(context.state.fees);
  context.commit(_types.mutations.SET_FEES_UPDATED_HASH, hashedFees);
  let hashedHistoryFees = hashHelper.hashObject(context.state.feeHistory);
  context.commit(_types.mutations.SET_FEE_HISTORY_UPDATED_HASH, hashedHistoryFees);
}

export default {
  [_types.actions.INITIALIZE_CAPITALS]: (context) => {
    context.commit(_types.mutations.SET_CAPITAL_MODAL_ACTIVE, { isNested: false, isActive: true });
    context.dispatch(_types.actions.FETCH_CAPITAL_ARTICLES);
  },

  [_types.actions.FETCH_CAPITAL_ARTICLES]: async (context) => {
    let capitalArticles = await feesApi.getCapitalArticles(context);
    context.commit(_types.mutations.SET_CAPITAL_ARTICLES, capitalArticles);
    context.commit(_types.mutations.SET_CAPITAL_ARTICLE_TYPE, '1e93811e-39da-4a42-9fc1-394d4926da78');
    context.dispatch(_types.actions.FETCH_CAPITAL_SERIES);
  },

  [_types.actions.FETCH_CAPITAL_SERIES]: async (context) => {
    let { selectedCapitalArticleId, capitalArticles } = context.state;
    if (!selectedCapitalArticleId) return;
    if (capitalArticles[selectedCapitalArticleId].capitals) return;
    let result = await feesApi.getCapitalSeries(context, selectedCapitalArticleId);
    context.commit(_types.mutations.SET_CAPITAL_SERIES, { capitals: result, id: selectedCapitalArticleId });
  },

  [_types.actions.SELECT_CAPITAL_ARTICLE]: (context, fieldValue) => {
    context.commit(_types.mutations.SET_FIELD, fieldValue);
    context.dispatch(_types.actions.FETCH_CAPITAL_SERIES);
  },

  [_types.actions.SELECT_CAPITAL_ARTICLE_TYPE]: (context, type) => {
    context.commit(_types.mutations.SET_CAPITAL_ARTICLE_TYPE, type);
    context.dispatch(_types.actions.FETCH_CAPITAL_SERIES);
  },

  [_types.actions.FILTER_CAPITAL_SERIES]: (context, filterObject) => {
    context.commit(_types.mutations.SET_FILTER_CAPITAL_SERIES, filterObject);
  },

  [_types.actions.SELECT_CAPITAL_SERIE]: (context, capitalSerie) => {
    const { capitalArticles, selectedCapitalArticleId } = context.state;
    const { name, capitalInvestmentTypeId } = capitalArticles[selectedCapitalArticleId];
    let result = capitalHelper.createCapital({
      ...capitalSerie,
      name: name,
      typeId: capitalInvestmentTypeId,
    });
    context.commit(_types.mutations.SET_SELECTED_CAPITAL_SERIE, result);
  },

  [_types.actions.ADD_CAPITAL_SERIES]: (context) => {
    let capitalSeries = Object.values(context.state.selectedCapitalSeries);
    context.commit(_types.mutations.ADD_FEES, capitalSeries);
    context.dispatch(_types.actions.CLEAR_CAPITAL_SERIES);
    _setHash(context);
  },

  [_types.actions.SHOW_SELECTED_CAPITAL_SERIE]: (context, fieldValue) => {
    context.commit(_types.mutations.SET_FIELD, fieldValue);
    context.commit(_types.mutations.SET_CAPITAL_MODAL_ACTIVE, { isNested: true, isActive: true });
  },

  [_types.actions.CLEAR_CAPITAL_SERIE]: (context) => {
    context.commit(_types.mutations.SET_FIELD, { field: 'selectedCapitalSerieId', value: undefined });
    context.commit(_types.mutations.SET_CAPITAL_MODAL_ACTIVE, { isNested: true, isActive: false });
  },

  [_types.actions.CLEAR_CAPITAL_SERIES]: (context) => {
    context.commit(_types.mutations.CLEAR_CAPITAL_SERIES);
    context.commit(_types.mutations.SET_CAPITAL_MODAL_ACTIVE, { isNested: false, isActive: false });
  },

  [_types.actions.SET_EDIT_CAPITAL]: (context, payload) => {
    context.commit(_types.mutations.CLEAR_EDIT_CAPITAL);
    context.commit(_types.mutations.SET_EDIT_CAPITAL_ID, payload);
    context.commit(_types.mutations.SET_ORIGINAL_CREATED_DATE, payload);
    context.commit(_types.mutations.SET_CAPITAL_HASH);
  },

  [_types.actions.EDIT_CAPITAL]: (context, payload) => {
    let hasChanges = context.getters[_types.getters.GET_CAPITAL_HAS_CHANGES];
    if (hasChanges) {
      context.dispatch(_types.actions.CLEAR_FORM);
    }
    context.commit(_types.mutations.EDIT_CAPITAL, payload);
  },

  [_types.actions.CONFIRM_EDIT_CAPITAL]: async (context) => {
    const { fees, editCapital, isCurrentUserChanged, selectedCurrentOwnerType, returnToClub } = context.state;
    let result = [];
    const selectedFee = { ...fees[editCapital.feeId] };
    if (isCurrentUserChanged) {
      selectedFee.isNewUser = true;
    }
    if (returnToClub) {
      selectedFee.returnToClub = true;
    }
    result.push(selectedFee);
    let payload = {
      personId: isCurrentUserChanged ? fees[editCapital.feeId].currentUser.id : fees[editCapital.feeId].currentOwner.id,
      fees: result,
      isCompany: selectedCurrentOwnerType == 'partner' ? true : false,
    };
    if (payload.fees[0].isForSale !== payload.fees[0].currentOwner.isForSale && isCurrentUserChanged) {
      const payload = {
        fees: [fees[editCapital.feeId]],
        personId: fees[editCapital.feeId].currentOwner.id,
      };
      await feesApi.saveEditCapital(context, payload);
    }
    await feesApi.saveEditCapital(context, payload);
    context.dispatch(_types.actions.EDIT_CAPITAL);
  },

  [_types.actions.SHOW_SEARCH_MODAL]: (context, payload) => {
    context.commit(_types.mutations.SEARCH_CLEANUP);
    context.commit(_types.mutations.SET_SEARCH_MODAL_ACTIVE, payload);
  },

  [_types.actions.SELECT_SEARCH_TYPE]: (context, fieldValue) => {
    context.commit(_types.mutations.SET_FIELD, fieldValue);
  },

  [_types.actions.ADD_SELECTED_PERSON]: (context, person) => {
    const { editCapital, isCurrentOwner } = context.state;
    if (isCurrentOwner) {
      let result = capitalHelper.addPersonOrPartner({
        ...person,
        ownerType: 'Person',
      });
      context.commit(_types.mutations.EDIT_FEE, { feeId: editCapital.feeId, field: 'currentOwner', value: result });
      context.commit(_types.mutations.SET_FIELD, { field: 'selectedCurrentOwnerType', value: 'person' });
    } else {
      let result = capitalHelper.addPersonOrPartner({
        ...person,
      });
      context.commit(_types.mutations.EDIT_FEE, { feeId: editCapital.feeId, field: 'currentUser', value: result });
      context.commit(_types.mutations.SET_FIELD, { field: 'isCurrentUserChanged', value: true });
    }
    context.commit(_types.mutations.SET_SEARCH_MODAL_ACTIVE, { isActive: false });
  },

  [_types.actions.ADD_SELECTED_PARTNER]: (context, partner) => {
    const { editCapital } = context.state;
    let result = capitalHelper.addPersonOrPartner({
      ...partner,
    });
    context.commit(_types.mutations.SET_FIELD, { field: 'selectedCurrentOwnerType', value: 'partner' });
    context.commit(_types.mutations.EDIT_FEE, { feeId: editCapital.feeId, field: 'currentOwner', value: result });
    context.commit(_types.mutations.SET_SEARCH_MODAL_ACTIVE, { isActive: false });
  },

  [_types.actions.SEARCH_PARTNER]: async (context, searchPartner) => {
    let result = await feesApi.getCompanies(context, searchPartner);
    context.commit(_types.mutations.SET_SEARCH_RESULT, result);
  },

  [_types.actions.TOGGLE_FOR_SALE_OR_REPAID]: (context, fieldValue) => {
    const { editCapital } = context.state;
    const { field, value } = fieldValue;
    context.commit(_types.mutations.EDIT_FEE, { feeId: editCapital.feeId, field: field, value: value });
  },

  [_types.actions.EDIT_CURRENT_OWNER_DATE]: (context, datepickerValue) => {
    context.commit(_types.mutations.SET_CURRENT_OWNER_DATE, datepickerValue);
  },

  [_types.actions.CONFIRM_DATE_CHANGED]: (context, payload) => {
    context.commit(_types.mutations.EDIT_CAPITAL, payload);
  },

  [_types.actions.CLOSE_DATE_CHANGED]: (context) => {
    context.commit(_types.mutations.SET_CHANGED_DATE_TO_ORIGINAL);
    context.commit(_types.mutations.EDIT_CAPITAL);
  },

  [_types.actions.SET_FIELD]: (context, fieldValue) => {
    context.commit(_types.mutations.SET_FIELD, fieldValue);
  },
};
