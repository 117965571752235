import { globalEventTypes, internalGlobalEventTypes, headlessHelper } from '@sgf/go-utilities';
import { personOverviewTypes } from '@sgf/go-person-overview';
import router from '../../routes/router';
export default {
  namespaced: true,
  namespace: globalEventTypes.namespace,
  actions: {
    [internalGlobalEventTypes.actions.ARTICLE_LOADED]: () => {},
    [internalGlobalEventTypes.actions.ARTICLE_CREATED]: () => {},
    [internalGlobalEventTypes.actions.PERSON_SAVED]: (context, payload) => {
      //payload { personId }
      let personId = payload.personId;
      if (headlessHelper.isHeadless()) {
        window.parent.postMessage({ type: 'person_saved', ...payload }, '*');
      }
      context.dispatch(personOverviewTypes.actions.REFETCH_PERSON, personId, {
        root: true,
      });
    },
    [internalGlobalEventTypes.actions.PERSON_CREATED]: (context, payload) => {
      //payload { personId }
      let personId = payload.personId;
      router.replace({
        name: 'personContactInformation',
        params: { personId: personId, force: true },
      });
    },
    [internalGlobalEventTypes.actions.PERSON_DELETED]: () => {
      router.replace({
        name: 'start',
      });
    },
  },
};
