import { ServiceBase } from '@sgf/go-api-services';

class FeeDetailsApi extends ServiceBase {
  _origin = 'feeDetailsApi';

  async getFeeUsage(context, personId, articleId, feeId) {
    return await this.fetch(
      `/persons/${personId}/fee/${articleId}/usage?feeId=${feeId}`,
      {
        method: 'GET',
      },
      context,
      'getFeeUsage',
      'Hämtar...'
    );
  }
  async postGameRightRound(context, payload, personId, engagementId) {
    let endpoint = `/persons/${personId}/rounds/create?engagementId=${engagementId}`;
    return await this.fetch(
      endpoint,
      {
        method: 'POST',
        body: JSON.stringify(payload),
      },
      context,
      'postGameRightNewRound',
      'Sparar...',
      false,
      true
    );
  }

  async postGameRightRoundDiscard(context, personId, id, slotBookingId, articleBookingId) {
    let endpoint = `/persons/${personId}/rounds/discard`;
    if (articleBookingId) {
      endpoint += `?articleBookingId=${articleBookingId}`;
    } else if (id) {
      endpoint += `?roundId=${id}`;
    } else if (slotBookingId) {
      endpoint += `?slotBookingId=${slotBookingId}`;
    }
    return await this.fetch(
      endpoint,
      {
        method: 'POST',
      },
      context,
      'postGameRightRoundDiscard',
      'Sparar...',
      false,
      true
    );
  }

  async postChangeFee(context, personId, payload) {
    let endpoint = `/persons/${personId}/rounds/changeFee`;
    return await this.fetch(
      endpoint,
      {
        method: 'POST',
        body: JSON.stringify(payload),
      },
      context,
      'postChangeFee',
      'Sparar...',
      false,
      true
    );
  }
}

export default FeeDetailsApi;
