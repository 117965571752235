import { _types } from './types';
import { hashHelper } from '@sgf/go-utilities';
import { validationHelper } from '../helpers';

export default {
  [_types.getters.HAS_PLAYER_CHANGED]: (state) => {
    if (state.hash === undefined) {
      return false;
    }
    if (state.hash !== hashHelper.hashObject(state.player)) {
      return true;
    }
    return false;
  },

  [_types.getters.GET_VALIDATIONS]: (state) => {
    if (!state.hasSubmitted) {
      return {};
    }
    let result = validationHelper.validate(state);

    return result.reduce((pv, cv) => {
      pv[cv.field] = cv.message;
      return pv;
    }, {});
  },
  [_types.getters.GET_VALIDATION_WARNINGS]: (state) => {
    if (!state.validation) {
      return [];
    }
    return state.validation.filter((x) => x.level === 'warning');
  },
};
