import Vue from 'vue';
import { _types } from './types';

export default {
  [_types.mutations.INITIALIZE]: (state, feeDetails) => {
    if (feeDetails.usageRights) {
      state.gameRights = feeDetails.usageRights.reduce((pv, cv) => {
        pv[cv.id] = cv;
        return pv;
      }, {});
      if (!state.selectedGameRight) {
        state.selectedGameRight = feeDetails.usageRights[0].id;
      }
    } else {
      state.gameRights = feeDetails.gameRights.reduce((pv, cv) => {
        pv[cv.id] = cv;
        return pv;
      }, {});
      if (!state.selectedGameRight) {
        state.selectedGameRight = feeDetails.gameRights[0].id;
      }
    }

    state.feeName = feeDetails.feeName;
    state.engagementId = feeDetails.engagementId;
    state.validFrom = feeDetails.validFrom;
    state.validTo = feeDetails.validTo;
    state.description = feeDetails.description;
    state.remainingCredits = feeDetails.remainingCredits;
    state.usedCredits = feeDetails.usedCredits;
    state.totalCredits = feeDetails.totalCredits;
    state.usageOccasions = feeDetails.usageOccasions;
  },
  [_types.mutations.SET_FIELD]: (state, fieldValue) => {
    state[fieldValue.field] = fieldValue.value;
  },
  [_types.mutations.SET_REMOVE_ROUND_PLAYER_FIELD]: (state, fieldValue) => {
    state.roundToRemove.player[fieldValue.field] = fieldValue.value;
  },
  [_types.mutations.CLEANUP]: (state) => {
    delete state.personId;
    delete state.articleId;
    delete state.feeId;
    delete state.gameRights;
    delete state.selectedGameRight;
    delete state.feeName;
    delete state.engagementId;
    delete state.validFrom;
    delete state.validTo;
    delete state.description;
    delete state.remainingCredits;
    delete state.usedCredits;
    delete state.totalCredits;
    delete state.usageOccasions;
  },
};
