import { _types } from './types';
import FeesApi from '../api/feesApi';
import router from '@router';
import feeHelper from '../helpers/fee-helper';
import { hashHelper } from '@sgf/go-utilities';
const feesApi = new FeesApi();
import actionsLockers from './actions.lockers';
import actionsCapital from './actions.capital';
import { personOverviewTypes } from '@sgf/go-person-overview';
import { globalEventTypes } from '@sgf/go-utilities';

function _setHash(context) {
  let hashedFees = hashHelper.hashObject(context.state.fees);
  context.commit(_types.mutations.SET_FEES_UPDATED_HASH, hashedFees);
}

function _setHashHistory(context) {
  let hashedFees = hashHelper.hashObject(context.state.feeHistory);
  context.commit(_types.mutations.SET_FEE_HISTORY_UPDATED_HASH, hashedFees);
}

export default {
  [_types.actions.FETCH_FEES]: async (context) => {
    context.commit(_types.mutations.SET_FEES, undefined);
    let personId = router.currentRoute.value.params.personId;
    if (!personId) return;
    let result = await feesApi.getPersonFees(context, personId);
    context.commit(_types.mutations.SET_FEES, result);
    context.commit(_types.mutations.SET_FEES_HASH);
  },

  [_types.actions.ACTIVATE_FEE]: (context, feeId) => {
    context.commit(_types.mutations.SET_ISACTIVE, feeId);
    _setHash(context);
  },

  [_types.actions.SET_REMOVE_FEE]: (context, payload) => {
    if (!payload) context.commit(_types.mutations.SET_REMOVE_FEE);
    else context.commit(_types.mutations.SET_REMOVE_FEE, { isHistory: payload.isHistory, feeId: payload.id });
  },

  [_types.actions.CONFIRM_REMOVE_FEE]: (context) => {
    let isHistory = context.state.removeFee.isHistory;
    context.commit(_types.mutations.REMOVE_FEE);
    context.dispatch(_types.actions.SET_REMOVE_FEE);
    if (isHistory) _setHashHistory(context);
    else _setHash(context);
  },

  [_types.actions.ADD_FEES]: (context, fees) => {
    let selectedDueDate = context.state.selectedDueDate;
    let result = feeHelper.createFees(fees, selectedDueDate);
    context.commit(_types.mutations.ADD_FEES, result);
    _setHash(context);
  },

  [_types.actions.FETCH_FEE_HISTORY]: async (context) => {
    context.commit(_types.mutations.SET_FEE_HISTORY, undefined);
    let personId = router.currentRoute.value.params.personId;
    if (!personId) return;
    let result = await feesApi.getPersonFeeHistory(context, personId);
    context.commit(_types.mutations.SET_FEE_HISTORY, result);
    context.commit(_types.mutations.SET_FEE_HISTORY_HASH);
  },

  [_types.actions.SAVE_FEES]: async (context, closeAfterSave) => {
    let personId = router.currentRoute.value.params.personId;

    let historyFeesToUpdate = [];
    const { feeHistory, sourceFeeHistory } = context.state;
    Object.keys(feeHistory).forEach((id) => {
      const currentHash = hashHelper.hashObject(feeHistory[id]);
      const sourceHash = hashHelper.hashObject(sourceFeeHistory[id]);
      if (currentHash !== sourceHash) {
        historyFeesToUpdate.push(feeHistory[id]);
      }
    });

    let payload = {
      personId: personId,
      fees: Object.values(context.state.fees).concat(historyFeesToUpdate),
      removedFees: context.state.feesRemoved,
    };

    await feesApi.saveFees(context, payload);
    context.dispatch(personOverviewTypes.actions.REFETCH_PERSON, personId, { root: true });
    context.dispatch(_types.actions.CLEAR_FORM);
    context.dispatch(globalEventTypes.actions.PERSON_SAVED, { personId: personId }, { root: true });
    if (closeAfterSave) router.push({ name: 'start' });
  },

  [_types.actions.CLEAR_FORM]: (context) => {
    context.commit(_types.mutations.CLEAR_FORM);
    context.dispatch(_types.actions.FETCH_FEES);
    context.dispatch(_types.actions.FETCH_FEE_HISTORY);
  },
  [_types.actions.SET_DUE_DATE]: (context, fieldValue) => {
    context.commit(_types.mutations.SET_DUE_DATE, fieldValue);
  },
  [_types.actions.INITIATE_ADD_ARTICLE]: (context) => {
    context.commit(_types.mutations.INITIATE_ADD_ARTICLE);
  },
  ...actionsLockers,
  ...actionsCapital,
  [_types.actions.CLEANUP]: (context) => {
    context.commit(_types.mutations.CLEANUP);
  },
};
