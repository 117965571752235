import { _types } from './types';
import FeesApi from '../api/feesApi';
import lockerHelper from '../helpers/locker-helper';
import { hashHelper } from '@sgf/go-utilities';
const feesApi = new FeesApi();

function _setHash(context) {
  let hashedFees = hashHelper.hashObject(context.state.fees);
  context.commit(_types.mutations.SET_FEES_UPDATED_HASH, hashedFees);
  let hashedHistoryFees = hashHelper.hashObject(context.state.feeHistory);
  context.commit(_types.mutations.SET_FEE_HISTORY_UPDATED_HASH, hashedHistoryFees);
}

export default {
  [_types.actions.INITIATE_ADD_LOCKER]: (context) => {
    context.commit(_types.mutations.SET_LOCKER_MODAL_ACTIVE, true);
    context.dispatch(_types.actions.FETCH_LOCKER_SERIES);
  },

  [_types.actions.FETCH_LOCKER_SERIES]: async (context) => {
    let lockerSeries = await feesApi.getLockerSeries(context);
    if (lockerSeries.length === 0) {
      context.commit(_types.mutations.SET_HAS_NO_LOCKERS);
    } else {
      context.commit(_types.mutations.SET_LOCKER_SERIES, lockerSeries);
      context.dispatch(_types.actions.FETCH_LOCKERS);
    }
  },

  [_types.actions.FETCH_LOCKERS]: async (context) => {
    let selectedLockerSeriesId = context.state.selectedLockerSeriesId;
    if (context.state.lockerSeries[selectedLockerSeriesId].lockers) return;
    let result = await feesApi.getLockers(context, selectedLockerSeriesId);
    context.commit(_types.mutations.SET_LOCKERS, { lockers: result, id: selectedLockerSeriesId });
  },

  [_types.actions.SELECT_LOCKER_SERIES]: (context, id) => {
    context.commit(_types.mutations.SET_SELECTED_LOCKER_SERIES_ID, id);
    context.dispatch(_types.actions.FETCH_LOCKERS);
  },

  [_types.actions.SELECT_LOCKER]: (context, locker) => {
    if (locker.isRented) return;

    let lockerSeriesName = context.state.lockerSeries[context.state.selectedLockerSeriesId].name;
    let user = context.rootState.authentication.user.profile;

    let result = lockerHelper.createLocker({
      ...locker,
      lockerSeriesName: lockerSeriesName,
      golfId: user.golfId,
      renterName: user.firstName + ' ' + user.lastName,
    });
    context.commit(_types.mutations.SET_SELECTED_LOCKER, result);
  },

  [_types.actions.ADD_LOCKERS]: (context) => {
    let lockers = Object.values(context.state.selectedLockers);
    context.commit(_types.mutations.ADD_FEES, lockers);
    context.dispatch(_types.actions.CLEAR_LOCKERS);
    _setHash(context);
  },

  [_types.actions.FILTER_LOCKERS]: (context, payload) => {
    context.commit(_types.mutations.SET_FILTER_LOCKERS, payload);
  },

  [_types.actions.SET_EDIT_LOCKER]: (context, payload) => {
    context.commit(_types.mutations.SET_EDIT_LOCKER_ID, payload);
  },

  [_types.actions.EDIT_LOCKER]: (context, payload) => {
    let locker = lockerHelper.getEditLockerPayload(payload);
    context.commit(_types.mutations.EDIT_LOCKER, locker);
  },

  [_types.actions.CONFIRM_EDIT_LOCKER]: (context) => {
    let locker = context.state.editLocker;
    context.commit(_types.mutations.EDIT_FEE, { feeId: locker.feeId, field: 'note', value: locker.note, isHistory: locker.isHistory });
    context.commit(_types.mutations.EDIT_FEE, { feeId: locker.feeId, field: 'rentedTo', value: locker.rentedTo, isHistory: locker.isHistory });
    context.commit(_types.mutations.EDIT_FEE, {
      feeId: locker.feeId,
      field: 'deleteAfterRentedTo',
      value: locker.deleteAfterRentedTo,
      isHistory: locker.isHistory,
    });
    context.commit(_types.mutations.EDIT_LOCKER, undefined);

    _setHash(context);
  },

  [_types.actions.CLEAR_LOCKERS]: (context) => {
    context.commit(_types.mutations.CLEAR_LOCKERS);
    context.commit(_types.mutations.SET_LOCKER_MODAL_ACTIVE, false);
  },
};
