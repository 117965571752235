import feeLockerTypes from './types';
import actions from './actions';
import getters from './getters';
import mutations from './mutations';
import state from './state';

export { feeLockerTypes };

export default {
  namespaced: true,
  namespace: feeLockerTypes.namespace,
  actions: actions,
  getters: getters,
  mutations: mutations,
  state: state,
};
