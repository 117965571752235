import { _types } from './types';
export default {
  [_types.mutations.CLEAR]: (state) => {
    state.player = {};
    state.originalPlayer = {};
    state.validation = undefined;
    state.hasSubmited = false;
  },
  [_types.mutations.SET_PERSON]: (state, person) => {
    state.player = person;
  },
  [_types.mutations.SET_HASH]: (state, hash) => {
    state.hash = hash;
  },
  [_types.mutations.SET_GOLF_CAR_PERMISSION_HASH]: (state, hashes) => {
    const { sgfDates, clubDates } = hashes;
    state.player.sgfDatesHash = sgfDates;
    state.player.clubDatesHash = clubDates;
  },
  [_types.mutations.SET_HAS_SUBMITED]: (state, value) => {
    state.hasSubmitted = value;
  },
  [_types.mutations.SET_FIELD]: (state, fieldValue) => {
    state.player[fieldValue.field + ''] = fieldValue.value;
  },
  [_types.mutations.SET_LICENS_FIELD]: (state, payload) => {
    state.player[payload.field] = payload.value;
  },
  [_types.mutations.SET_VALIDATION]: (state, validationMessage) => {
    state.validation = validationMessage;
  },
  [_types.mutations.SET_GOLF_CAR_PERMISSION_DATE]: (state, payload) => {
    state.player.golfCarPermission[payload.type] = payload.value;
  },
  [_types.actions.SET_PERSON_TO_DELETE]: (state, pid) => {
    state.personToDelete = pid;
  },
  [_types.mutations.SET_DELETE_PERSON_INFORMATION]: (state, value) => {
    state.deletePersonInformation = value;
  },
};
