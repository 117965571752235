import { _types } from './types';
import FeeDetailsApi from '../api/feeDetailsApi';
const feeDetailsApi = new FeeDetailsApi();
import changeFeePlayloadHelper from '../helpers/changeFeePayloadHelper';
export default {
  [_types.actions.INITIALIZE]: async (context, personIdFeeIdArticleId) => {
    let { personId, articleId, feeId } = personIdFeeIdArticleId;
    context.commit(_types.mutations.SET_FIELD, { field: 'personId', value: personId });
    context.commit(_types.mutations.SET_FIELD, { field: 'articleId', value: articleId });
    context.commit(_types.mutations.SET_FIELD, { field: 'feeId', value: feeId });
    let result = await feeDetailsApi.getFeeUsage(context, personId, articleId, feeId);
    context.commit(_types.mutations.INITIALIZE, result);
  },
  [_types.actions.REINITIALIZE]: async (context) => {
    context.commit(_types.mutations.SET_FIELD, { field: 'feeName', value: undefined });
    let result = await feeDetailsApi.getFeeUsage(context, context.state.personId, context.state.articleId, context.state.feeId);
    context.commit(_types.mutations.INITIALIZE, result);
  },
  [_types.actions.SUBMIT_ROUND]: async (context, payload) => {
    await feeDetailsApi.postGameRightRound(context, payload, context.state.personId, context.state.engagementId);
    context.commit(_types.mutations.SET_FIELD, { field: 'isAddRoundActive', value: false });
    context.dispatch(_types.actions.REINITIALIZE);
  },
  [_types.actions.REMOVE_ROUND]: async (context, idSlotBookingId) => {
    let { id, slotBookingId, number, articleBookingId } = idSlotBookingId;
    let result = await feeDetailsApi.postGameRightRoundDiscard(context, context.state.personId, id, slotBookingId, articleBookingId);
    if (!result) {
      context.dispatch(_types.actions.REINITIALIZE);
    } else {
      context.commit(_types.mutations.SET_FIELD, { field: 'roundToRemove', value: result });
      context.commit(_types.mutations.SET_FIELD, { field: 'selectedRound', value: number });
    }
  },
  [_types.actions.SET_REMOVE_ROUND_PLAYER_FIELD]: (context, fieldValue) => {
    context.commit(_types.mutations.SET_REMOVE_ROUND_PLAYER_FIELD, fieldValue);
  },
  [_types.actions.SUBMIT_REMOVE_ROUND]: async (context, selectedFee) => {
    let payload = changeFeePlayloadHelper.getPayload(context.state, selectedFee);
    await feeDetailsApi.postChangeFee(context, context.state.personId, payload);
    context.commit(_types.mutations.SET_FIELD, { field: 'roundToRemove', value: undefined });
    context.commit(_types.mutations.SET_FIELD, { field: 'selectedRound', value: undefined });
    context.dispatch(_types.actions.REINITIALIZE);
  },
  [_types.actions.SET_FIELD]: (context, fieldValue) => {
    context.commit(_types.mutations.SET_FIELD, fieldValue);
  },
  [_types.actions.CLEANUP]: (context) => {
    context.commit(_types.mutations.CLEANUP);
  },
};
