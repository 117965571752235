import { TypesBase } from '@sgf/go-utilities';

class InvoiceTypes extends TypesBase {
  constructor() {
    super('invoice');
  }

  getters = {
    GET_VALIDATION: 'getValidation',
  };

  mutations = {
    SET_FIELD: 'setField',
    CLEANUP: 'cleanup',
    TOGGLE_ENGAGEMENT_ID: 'toggleEngagmentId',
    TOGGLE_ORGANIZATION_SELLING_ID: 'toggleOrganizationSellingId',
    CLEAR_BANKGIRO_INFO: 'clearBankgiroInfo',
  };

  actions = {
    INITIALIZE: 'initialize',
    INITIALIZE_BANKGIRO: 'initializeBankgiro',
    FETCH_SELLING_ORGS: 'fetchSellingOrgs',
    POST_BANKGIRO_JOB: 'fetchBankgiroJobResults',
    SET_FIELD: 'setField',
    TOGGLE_ENGAGEMENT_ID: 'toggleEngagmentId',
    SUBMIT: 'submit',
    FETCH_JOB_RESULTS: 'fetchJobResults',
    CLEAR_JOB_RESULT: 'clearJobResult',
    CLEANUP: 'cleanup',
    CONFIRM_SUBMIT: 'confirmSubmit',
    FETCH_SETTINGS: 'fetchSettings',
    CLEAR_BANKGIRO_INFO: 'clearBankgiroInfo',
  };
}

class NamespacedInvoiceTypes extends InvoiceTypes {
  constructor() {
    super();
    this.toNamespaced();
  }
}

const types = new NamespacedInvoiceTypes();
const _types = new InvoiceTypes();
export { _types };
export default types;
