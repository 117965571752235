import { _types } from './types';
import { hashHelper } from '@sgf/go-utilities';
import capitalValidationHelper from '../helpers/capitalValidationHelper';
import moment from 'moment/moment';

export default {
  [_types.getters.GET_LOCKERS]: (state) => {
    if (!state.lockerSeries) return;
    let filter = state.filter;
    let lockerSeriesId = state.selectedLockerSeriesId;
    let lockers = state.lockerSeries[lockerSeriesId].lockers;
    if (!lockers) {
      return [];
    }
    let result = [];

    if (!filter.onlyUnrented && !filter.numberFrom && !filter.numberTo) return lockers;

    lockers.forEach((locker) => {
      let add = true;
      if (filter.onlyUnrented && locker.isRented) {
        add = false;
      }
      if (filter.numberFrom && locker.number < filter.numberFrom) {
        add = false;
      }
      if (filter.numberTo && locker.number > filter.numberTo) {
        add = false;
      }
      if (add) result.push(locker);
    });

    return result;
  },
  [_types.getters.GET_CAPITAL_ARTICLES]: (state) => {
    const { capitalArticles, selectedCapitalArticleType } = state;
    if (!capitalArticles) {
      return [];
    }
    let result = Object.values(capitalArticles)
      .filter((o) => o.capitalInvestmentTypeId == selectedCapitalArticleType)
      .map((o) => {
        return {
          label: o.name,
          value: o.id,
        };
      });

    if (result.length == 0) {
      return [
        {
          label: '---',
          value: null,
        },
      ];
    }
    return result.sort((a, b) => a.label.localeCompare(b.label));
  },
  [_types.getters.GET_CAPITAL_SERIES]: (state) => {
    let { selectedCapitalArticleId, capitalArticles, capitalFilter } = state;
    if (!capitalArticles) return;
    if (!selectedCapitalArticleId) {
      return [];
    }
    let capitals = capitalArticles[selectedCapitalArticleId].capitals;
    if (!capitals) {
      return [];
    }

    capitals.sort((a, b) => {
      const serialNumberA = a.serialNumber.split('-')[0];
      const serialNumberB = b.serialNumber.split('-')[0];
      const numA = Number(serialNumberA);
      const numB = Number(serialNumberB);
      return numA - numB;
    });

    let result = [];
    if (!capitalFilter.onlyForSale && !capitalFilter.numberFrom && !capitalFilter.numberTo) return capitals;
    capitals.forEach((capital) => {
      let add = true;
      if (capitalFilter.numberFrom) {
        const serialNumberParts = capital.serialNumber.split('-');
        const firstNumber = Number(serialNumberParts[0]);
        if (firstNumber < capitalFilter.numberFrom) {
          add = false;
        }
      }
      if (capitalFilter.numberTo) {
        const serialNumberParts = capital.serialNumber.split('-');
        const firstNumber = Number(serialNumberParts[0]);
        if (firstNumber > capitalFilter.numberTo) {
          add = false;
        }
      }
      if (add) {
        result.push(capital);
      }
    });
    return result;
  },
  [_types.getters.GET_CAPITAL_HAS_CHANGES]: (state) => {
    if (state.capitalHash === undefined) {
      return false;
    }
    const { feeId } = state.editCapital;
    let newHash = hashHelper.hashObject([state.returnToClub, state.selectedCurrentOwnerType, state.isCurrentUserChanged, state.fees[feeId]]);
    if (newHash !== state.capitalHash) {
      return true;
    }
    return false;
  },
  [_types.getters.GET_VALIDATIONS]: (state) => {
    let result = [];
    if (moment(state.selectedDueDate).isBefore(moment())) {
      result.push({ field: 'selectedDueDate', message: 'Välj ett datum som är senare än dagens datum' });
    }
    if (!state.selectedDueDate) {
      result.push({ field: 'selectedDueDate', message: 'Förfallodatum måste väljas' });
    }
    return result.reduce((pv, cv) => {
      pv[cv.field] = cv.message;
      return pv;
    }, {});
  },
  [_types.getters.GET_CAPITAL_VALIDATION_WARNINGS]: (state) => {
    if (!state.editCapital.feeId) {
      return;
    }
    let allValidations = capitalValidationHelper.validate(state);
    let validationWarnings = [];
    if (allValidations.length > 0) {
      allValidations.forEach((object) => {
        validationWarnings.push(object);
      });
    }
    if (validationWarnings.length === 0) {
      return {};
    }
    return validationWarnings.reduce((res, obj) => {
      res[obj.field] = obj.msg;
      return res;
    }, {});
  },
};
