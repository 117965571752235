import actions from './actions';
import miscellaneousTypes from './types';
import getters from './getters';
import mutations from './mutations';
import state from './state';

export default {
  namespaced: true,
  namespace: miscellaneousTypes.namespace,
  actions: actions,
  getters: getters,
  mutations: mutations,
  state: state,
};
