import { _types } from './types';
import { personInformationTypes } from '@sgf/go-person-information';
import { familyGroupTypes } from '@sgf/go-person-familygroup';

export default {
  [_types.actions.SAVE_PERSON]: (context, verifiedFamilyGroupAddress) => {
    let footer = context.rootGetters[personInformationTypes.getters.GET_PERSON_INFORMATION_FOOTER];
    if (footer.hasError) {
      context.dispatch(personInformationTypes.actions.SET_VALIDATION, { field: 'isPersonValidated', value: true }, { root: true });
    } else {
      let isAddressChanged = context.rootState.personInformation.hashes.isAddressChanged;
      let hasFamilyGroupMembers = Object.keys(context.rootState.familyGroup.currentFamilyGroup).length > 1;

      if (isAddressChanged && hasFamilyGroupMembers && !verifiedFamilyGroupAddress) {
        context.dispatch(personInformationTypes.actions.SET_VALIDATION, { field: 'isAddressChanged', value: true }, { root: true });
        context.dispatch(personInformationTypes.actions.SET_VALIDATION, { field: 'changeAddressFamilyGroup', value: false }, { root: true });
        return;
      } else {
        context.dispatch(personInformationTypes.actions.SAVE_PERSON, null, { root: true });
        context.dispatch(familyGroupTypes.actions.SUBMIT_FORM, null, { root: true });
      }
    }
  },
};
