import { TypesBase } from '@sgf/go-utilities';

class MiscellaneousTypes extends TypesBase {
  constructor() {
    super('miscellaneous');
  }
  getters = {
    HAS_PLAYER_CHANGED: 'hasPlayerChanged',
    GET_VALIDATIONS: 'getFieldValidation',
    GET_ERROR: 'getError',
    GET_VALIDATION_WARNINGS: 'getValidationWarnings',
    GET_VALIDATION_ERRORS: 'getValidationErrors',
  };

  mutations = {
    CLEAR: 'clear',
    SET_HASH: 'setHash',
    SET_PERSON: 'setPerson',
    SET_VALIDATION: 'setValidation',
    SET_FIELD: 'setField',
    SET_LICENS_FIELD: 'setLicensField',
    SET_HAS_SUBMITED: 'setHasSubmited',
    SET_GOLF_CAR_PERMISSION_DATE: 'setGolfCarPermissionField',
    SET_PERSON_TO_DELETE: 'setPersonToDelete',
    SET_DELETE_PERSON_INFORMATION: 'setDeletePersonInformation',
    SET_GOLF_CAR_PERMISSION_HASH: 'setGolfCarPermissionHash',
  };

  actions = {
    INITIALIZE: 'initialize',
    DELETE_PERSON: 'deletePerson',
    CLEAR: 'clear',
    FETCH_PERSON: 'fetchPerson',
    SAVE_MISCELLANEOUS: 'saveMiscellaneous',
    SET_FIELD: 'setField',
    SET_LICENS_FIELD: 'setLicensField',
    SET_GOLF_CAR_PERMISSION_DATE: 'setGolfCarPermissionField',
    SUBMIT_MISCELLANEOUS: 'submitMiscellaneous',
    VALIDATE_GOLF_CAR_PERMISSION: 'validateGolfCarPermission',
    SET_PERSON_TO_DELETE: 'setPersonToDelete',
    CLOSE_PERSON_RESULT: 'closePersonResult',
  };
}

class NamespacedMiscellaneousTypes extends MiscellaneousTypes {
  constructor() {
    super();
    this.toNamespaced();
  }
}

const types = new NamespacedMiscellaneousTypes();
const _types = new MiscellaneousTypes();
export { _types };
export default types;
