import { TypesBase } from '@sgf/go-utilities';

class FeeDetailsTypes extends TypesBase {
  constructor() {
    super('feeDetails');
  }

  getters = {};

  mutations = {
    INITIALIZE: 'initialize',
    SET_FIELD: 'setField',
    SET_REMOVE_ROUND_PLAYER_FIELD: 'setRemoveRoundPlayerField',
    CLEANUP: 'cleanup',
  };

  actions = {
    INITIALIZE: 'initialize',
    REINITIALIZE: 'reinitialize',
    SUBMIT_ROUND: 'submitRound',
    REMOVE_ROUND: 'removeRound',
    SET_REMOVE_ROUND_PLAYER_FIELD: 'setRemoveRoundPlayerField',
    SUBMIT_REMOVE_ROUND: 'submitRemoveRound',
    SET_FIELD: 'setField',
    CLEANUP: 'cleanup',
  };
}

class NamespacedFeeDetailsTypes extends FeeDetailsTypes {
  constructor() {
    super();
    this.toNamespaced();
  }
}

const types = new NamespacedFeeDetailsTypes();
const _types = new FeeDetailsTypes();
export { _types };
export default types;
