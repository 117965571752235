import personDetailsTypes from './types';
import actions from './actions';
import getters from './getters';
import mutations from './mutations';
import state from './state';

export { personDetailsTypes };

export default {
  namespaced: true,
  actions: actions,
  getters: getters,
  mutations: mutations,
  state: state,
};
