import moment from 'moment';
import { invoiceTypeConstants } from '../constants';

export default {
  golfId: undefined,
  jobResult: undefined,
  excludedEngagementIds: [],
  includedSellingOrgIds: [],
  isPreview: true,
  inProgress: false,
  jobId: undefined,
  selectedSellingOrganization: null,
  selectedYear: moment().format('YYYY'),
  selectedInvoiceType: invoiceTypeConstants.FINQR,
  includeFamilyMemberFees: false,
  bankgiroError: undefined,
  sellingOrganizations: [],
};
