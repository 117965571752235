import Vue from 'vue';
import { _types } from './types';
import { invoiceTypeConstants } from '../constants';

export default {
  [_types.mutations.SET_FIELD]: (state, fieldValue) => {
    state[fieldValue.field] = fieldValue.value;
  },
  [_types.mutations.CLEANUP]: (state) => {
    delete state.golfId;
    delete state.jobResult;
    delete state.jobId;
    state.selectedSellingOrganization = null;
    state.excludedEngagementIds = [];
    state.isPreview = true;
    state.inProgress = false;
    state.selectedInvoiceType = invoiceTypeConstants.FINQR;
    state.selectedYear = new Date().getFullYear().toString();
    state.sellingOrganizations = [];
    state.includedSellingOrgIds = [];
    state.includeFamilyMemberFees = false;
  },
  [_types.mutations.TOGGLE_ENGAGEMENT_ID]: (state, object) => {
    const engagementId = object.engagmentId;
    let index = state.excludedEngagementIds.indexOf(engagementId);
    if (index === -1) {
      state.excludedEngagementIds.push(engagementId);
    } else {
      state.excludedEngagementIds.splice(index, 1);
    }
  },

  [_types.mutations.TOGGLE_ORGANIZATION_SELLING_ID]: (state, object) => {
    let index = state.includedSellingOrgIds.indexOf(object);
    if (index === -1) {
      state.includedSellingOrgIds.push(object);
    } else {
      state.includedSellingOrgIds.splice(index, 1);
    }
  },

  [_types.mutations.CLEAR_BANKGIRO_INFO]: (state) => {
    state.jobResult = undefined;
  },
};
