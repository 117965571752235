import { TypesBase } from '@sgf/go-utilities';

class FeeLockerTypes extends TypesBase {
  constructor() {
    super('feeLocker');
  }

  getters = {
    GET_LOCKERS: 'getLockers',
    GET_VALIDATIONS: 'getValidations',
    GET_CAPITAL_ARTICLES: 'getCapitalArticles',
    GET_CAPITAL_SERIES: 'getCapitalSeries',
    GET_CAPITAL_HAS_CHANGES: 'getCapitalHasChanges',
    GET_CAPITAL_VALIDATION_WARNINGS: 'getCapitalValidationWarnings',
  };

  mutations = {
    SET_CAPITAL_MODAL_ACTIVE: 'setCapitalModalActive',
    SET_CAPITAL_ARTICLES: 'setCapitalArticles',
    SET_CAPITAL_ARTICLE_TYPE: 'setCapitalArticleType',
    SET_CAPITAL_SERIES: 'setCapitalSeries',
    SET_FILTER_CAPITAL_SERIES: 'setFilterCapitalSeries',
    SET_SELECTED_CAPITAL_SERIE: 'setSelectCapitalSerie',
    CLEAR_CAPITAL_SERIES: 'clearCapitalSeries',
    SET_EDIT_CAPITAL_ID: 'setEditCapitalId',
    SET_CAPITAL_HASH: 'setCapitalHash',
    CLEAR_EDIT_CAPITAL: 'clearEditCapital',
    EDIT_CAPITAL: 'editCapital',
    SET_SEARCH_MODAL_ACTIVE: 'setSearchModalActive',
    SET_SEARCH_RESULT: 'setSearchResult',
    SET_FIELD: 'setField',
    SEARCH_CLEANUP: 'searchCleanup',
    SET_CURRENT_OWNER_DATE: 'setEditCurrentOwnerDate',
    SET_ORIGINAL_CREATED_DATE: 'setOriginalCreatedDate',
    SET_CHANGED_DATE_TO_ORIGINAL: 'setChangedDateToOriginal',

    SET_LOCKER_MODAL_ACTIVE: 'setockerModalActive',
    SET_LOCKER_SERIES: 'setLockerSeries',
    SET_LOCKERS: 'setLockers',
    SET_SELECTED_LOCKER: 'setSelectedLocker',
    CLEAR_LOCKERS: 'clearLockers',
    SET_SELECTED_LOCKER_SERIES_ID: 'setSelectedLockerSeriesId',
    SET_FILTER_LOCKERS: 'setFilterLockers',
    SET_EDIT_LOCKER_ID: 'setEditLocker',
    EDIT_LOCKER: 'editLocker',
    SET_HAS_NO_LOCKERS: 'setHasNoLockers',

    SET_FEES: 'setFees',
    SET_FEE_HISTORY: 'setFeeHistory',
    SET_ISACTIVE: 'setIsActive',
    SET_REMOVE_FEE: 'setRemoveFee',
    REMOVE_FEE: 'removeFee',
    ADD_FEES: 'addFees',
    EDIT_FEE: 'editFee',
    CLEAR_FORM: 'clearForm',

    SET_FEES_HASH: 'setFeesHash',
    SET_FEES_UPDATED_HASH: 'setFeesUpdatedHash',
    SET_FEE_HISTORY_UPDATED_HASH: 'setFeeHistoryUpdatedHash',
    SET_FEE_HISTORY_HASH: 'setfeeHistoryHash',

    SET_DUE_DATE: 'setDueDate',

    INITIATE_ADD_ARTICLE: 'initiateAddArticle',
    CLEANUP: 'cleanup',
  };

  actions = {
    INITIALIZE_CAPITALS: 'initializeCapitals',
    FETCH_CAPITAL_ARTICLES: 'fetchCapitalArticles',
    FETCH_CAPITAL_SERIES: 'fetchCapitalSeries',
    SELECT_CAPITAL_ARTICLE: 'selectCapitalArticle',
    SELECT_CAPITAL_ARTICLE_TYPE: 'selectCapitalArticleType',
    SELECT_CAPITAL_SERIE: 'selectCapitalSerie',
    FILTER_CAPITAL_SERIES: 'filterCapitalSeries',
    ADD_CAPITAL_SERIES: 'addCapitalSeries',
    SHOW_SELECTED_CAPITAL_SERIE: 'showSelectedCapitalSerie',
    CLEAR_CAPITAL_SERIES: 'clearCapitalSeries',
    CLEAR_CAPITAL_SERIE: 'clearCapitalSerie',
    SET_EDIT_CAPITAL: 'setEditCapital',
    EDIT_CAPITAL: 'editCapital',
    CONFIRM_EDIT_CAPITAL: 'confirmEditCapital',
    SHOW_SEARCH_MODAL: 'showSearchModal',
    SELECT_SEARCH_TYPE: 'selectSearchType',
    ADD_SELECTED_PERSON: 'addSelectedPerson',
    ADD_SELECTED_PARTNER: 'addSelectedPartner',
    SEARCH_PARTNER: 'searchPartner',
    TOGGLE_FOR_SALE_OR_REPAID: 'toggleForSaleOrRepaid',
    EDIT_CURRENT_OWNER_DATE: 'editCurrentOwnerDate',
    CONFIRM_DATE_CHANGED: 'confirmDateChanged',
    CLOSE_DATE_CHANGED: 'closeDateChanged',
    SET_FIELD: 'setField',

    INITIATE_ADD_LOCKER: 'initiateAddLocker',
    FETCH_FEES: 'fetchFees',
    ACTIVATE_FEE: 'activateFee',
    SET_REMOVE_FEE: 'setRemoveFee',
    CONFIRM_REMOVE_FEE: 'confirmRemoveFee',
    ADD_FEES: 'addFees',
    FETCH_LOCKER_SERIES: 'fetchLockerSeries',
    FETCH_LOCKERS: 'fetchLockers',
    SELECT_LOCKER_SERIES: 'selectLockerSeries',
    SELECT_LOCKER: 'selectLocker',
    ADD_LOCKERS: 'addLockers',
    FILTER_LOCKERS: 'filterLockers',
    FETCH_FEE_HISTORY: 'fetchFeeHistory',
    SAVE_FEES: 'savefees',
    CLEAR_LOCKERS: 'clearLockers',
    SET_EDIT_LOCKER: 'setEditLocker',
    EDIT_LOCKER: 'editLocker',
    CONFIRM_EDIT_LOCKER: 'confirmEditLocker',
    CLEAR_FORM: 'clearForm',
    SET_DUE_DATE: 'setDueDate',
    INITIATE_ADD_ARTICLE: 'initiateAddArticle',
    CLEANUP: 'cleanup',
  };
}

class NamespacedFeeLockerTypes extends FeeLockerTypes {
  constructor() {
    super();
    this.toNamespaced();
  }
}

const types = new NamespacedFeeLockerTypes();
const _types = new FeeLockerTypes();
export { _types };
export default types;
