import { capitalTypes } from '../constants';

class ValidationMessage {
  constructor(field, msg) {
    this.msg = msg;
    this.field = field;
  }
}

function validateCurrentOwnerChanged(state, result) {
  const { selectedCurrentOwnerType, fees, editCapital } = state;
  const { typeId, canBeAssignedToOtherPerson } = fees[editCapital.feeId];
  if (typeId !== capitalTypes.LOAN_TO_CLUB && canBeAssignedToOtherPerson) {
    if (selectedCurrentOwnerType) {
      result.push(
        new ValidationMessage('changedOwner', [
          'Du kan inte byta nuvarande nyttjare om du har bytt nuvarande ägare.',
          'Du kan inte ändra till försäljning om du har bytt nuvarande ägare.',
        ])
      );
    }
  }
}

function validateCurrentUserChanged(state, result) {
  const { isCurrentUserChanged } = state;
  if (isCurrentUserChanged) {
    result.push(new ValidationMessage('changedUser', 'Du kan inte byta nuvarande ägare om du har bytt nuvarande nyttjare.'));
  }
}

function validateCurrentOwnerChangedAndGotCurrentUser(state, result) {
  const { selectedCurrentOwnerType, fees, editCapital } = state;
  const selectedCapitalCurrentUser = fees[editCapital.feeId].currentUser;
  const selectedCapitalTypeId = fees[editCapital.feeId].typeId;
  if (selectedCapitalTypeId !== capitalTypes.LOAN_TO_CLUB) {
    if (selectedCurrentOwnerType && selectedCapitalCurrentUser !== null) {
      result.push(new ValidationMessage('changedOwnerAndGotUser', 'Byter du nuvarande ägare kommer nuvarande nyttjare försvinna från kapitalinsatsen.'));
    }
  }
}

function validateRepaidLoansChanged(state, result) {
  const { fees, editCapital, status } = state;
  const selectedCapitalIsRepaid = fees[editCapital.feeId].isRepaid;
  const selectedCapitalTypeId = fees[editCapital.feeId].typeId;
  if (selectedCapitalTypeId == capitalTypes.LOAN_TO_CLUB) {
    if (selectedCapitalIsRepaid && status !== 'Återbetald') {
      result.push(new ValidationMessage('changedIsRepaid', 'Är du säker på att du vill återbetala lånet?.'));
    }
  }
}

function validateIsForSaleChanged(state, result) {
  const { fees, editCapital } = state;
  const capitalIsForSale = fees[editCapital.feeId].isForSale;
  const currentOwnerIsForSale = fees[editCapital.feeId].currentOwner.isForSale;
  if (capitalIsForSale !== currentOwnerIsForSale) {
    result.push(new ValidationMessage('changedIsForSale', 'Du kan inte byta ägare samtidigt som du byter till försäljning. Spara först.'));
  }
}

function validateIsReturnToClubChanged(state, result) {
  const { returnToClub } = state;
  if (returnToClub) {
    result.push(new ValidationMessage('changedReturnToClub', 'Kapitalinsatsen ska nu tas bort från personen och återlämnas till klubbens register.'));
  }
}

export default {
  validate(state) {
    let result = [];
    validateCurrentOwnerChanged(state, result);
    validateCurrentUserChanged(state, result);
    validateCurrentOwnerChangedAndGotCurrentUser(state, result);
    validateRepaidLoansChanged(state, result);
    validateIsForSaleChanged(state, result);
    validateIsReturnToClubChanged(state, result);
    return result;
  },
};
