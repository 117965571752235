<template>
  <AppShell id="app">
    <AppAuthenticationView :roles="roles">
      <router-view />
    </AppAuthenticationView>
    <ApiView :correlationIds="correlationIds" />
  </AppShell>
</template>

<script>
import { AppShell } from '@sgf/go-ace-ui';
import { AppAuthenticationView } from '@sgf/go-authentication';
import { headlessHelper } from '@sgf/go-utilities';
import { ApiView } from '@sgf/go-api-services';
import roles from './roles';
export default {
  name: 'App',
  components: {
    AppShell,
    AppAuthenticationView,
    ApiView,
  },
  computed: {
    correlationIds() {
      return ['postGameRightNewRound', 'postGameRightRoundDiscard', 'postChangeFee', 'deletePerson', 'saveEditCapital'];
    },
    roles: () => roles,
  },
  mounted() {
    if (headlessHelper.isHeadless()) {
      this.$router.push({ name: 'personContactInformation', params: { personId: window.location.hash.replace('#/', '') } });
    }
  },
};
</script>

<style>
@import '../node_modules/@sgf/go-fonts/fonts.css';
:root {
  --ace-c-section-primary: var(--ace-c-secondary-yellow) !important;
  --ace-c-section-secondary: var(--ace-c-secondary-yellow-light) !important;
  --ace-c-section-table-highlight: var(--ace-c-table-highlight-yellow) !important;
  --ace-c-section-table-hover: var(--ace-c-table-hover-yellow) !important;
}
</style>
<style scoped></style>
