import { TypesBase } from '@sgf/go-utilities';

class PersonDetailsTypes extends TypesBase {
  constructor() {
    super('personDetails');
  }
  getters = {};

  mutations = {};

  actions = {
    SAVE_PERSON: 'savePerson',
    DELETE_PERSON: 'deletePerson',
  };
}

class NamespacedPersonDetailsTypes extends PersonDetailsTypes {
  constructor() {
    super();
    this.toNamespaced();
  }
}

const types = new NamespacedPersonDetailsTypes();
const _types = new PersonDetailsTypes();
export { _types };
export default types;
