import moment from 'moment';
export default {
  createLocker(locker) {
    return {
      id: locker.id,
      name: locker.lockerSeriesName,
      number: locker.number,
      uploaded: moment().format('YYYY-MM-DD'),
      isActive: false,
      price: locker.price,
      status: '',
      balance: locker.price,
      renterName: locker.renterName,
      isAdded: true,
      golfId: locker.golfId,
      invoiceHistory: [],
      paymentHistory: [],
      note: locker.note,
    };
  },
  getEditLockerPayload(payload) {
    if (!payload) return;
    if (payload.value !== 'rentedTo') return payload;

    payload.value = payload.value + 'T00:00:00+00:00';
    return payload;
  },
};
