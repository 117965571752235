import { createRouter, createWebHashHistory, createMemoryHistory } from 'vue-router';
const PersonPage = () => import('../modules/person/components/pages/PersonPage');
const CreatePersonPage = () => import('../modules/personDetails/components/pages/CreatePersonPage');
const ContactInformationPage = () => import('../modules/personDetails/components/pages/ContactInformationPage/ContactInformationPage');
const MembershipPage = () => import('../modules/membership/components/pages/MembershipPage');
const SearchPage = () => import('../modules/start/components/pages/SearchPage');
const EditArticlePage = () => import('../modules/feeLocker/components/pages/EditArticlePage');
const FeeLockerPage = () => import('../modules/feeLocker/components/pages/FeeLockerPage');
const AddArticlePage = () => import('../modules/feeLocker/components/pages/AddArticlePage');
const LogPage = () => import('../modules/log/components/pages/LogPage');
const InvoicePage = () => import('../modules/invoice/components/pages/InvoicePage');
import { headlessHelper } from '@sgf/go-utilities';
export default createRouter({
  history: headlessHelper.isHeadless() ? createMemoryHistory() : createWebHashHistory(),
  routes: [
    {
      path: '/',
      name: 'start',
      component: SearchPage,
    },
    {
      path: '/create',
      name: 'createPerson',
      component: CreatePersonPage,
    },
    {
      path: '/person/:personId',
      name: 'person',
      component: PersonPage,
      children: [
        {
          path: '/person/:personId/contactInformation/:force?',
          name: 'personContactInformation',
          component: ContactInformationPage,
        },
        {
          path: '/person/:personId/personFeeLocker',
          name: 'personFeeLocker',
          component: FeeLockerPage,
          children: [
            {
              path: '/person/:personId/personFeeLocker/editArticle/:id/:typeId/:name/:mainType',
              name: 'personEditArticle',
              component: EditArticlePage,
            },
            {
              path: '/person/:personId/personFeeLocker/addArticle',
              name: 'personAddArticle',
              component: AddArticlePage,
            },
            {
              path: '/person/:personId/personFeeLocker/invoice/:invoiceType',
              name: 'personFeeLockerInvoice',
              component: InvoicePage,
            },
          ],
        },
        {
          path: '/person/:personId/personMembership',
          name: 'personMembership',
          component: MembershipPage,
        },
        {
          path: '/person/:personId/log',
          name: 'personLog',
          component: LogPage,
        },
      ],
    },
  ],
  scrollBehavior() {
    if (window.InlineManual) {
      window.InlineManual('update');
    }
    document.getElementById('ace-main-content').scrollTo(0, 0);
    return { x: 0, y: 0 };
  },
});
