import Vue from 'vue';
import moment from 'moment';
import { _types } from './types';
import { hashHelper } from '@sgf/go-utilities';

export default {
  [_types.mutations.SET_FEES]: (state, payload) => {
    if (!payload) {
      state.fees = payload;
    } else {
      let result = payload.reduce((obj, item) => {
        obj[item.id] = item;
        return obj;
      }, {});
      state.fees = result;
    }
  },

  [_types.mutations.SET_ISACTIVE]: (state, feeId) => {
    let isActive = state.fees[feeId].isActive;
    state.fees[feeId].isActive = !isActive;
  },

  [_types.mutations.SET_REMOVE_FEE]: (state, payload) => {
    if (!payload) {
      delete state.removeFee;
    } else {
      state.removeFee = payload;
    }
  },

  [_types.mutations.ADD_FEES]: (state, payload) => {
    payload.forEach((fee) => {
      state.fees[fee.id] = { ...fee };
    });
  },

  [_types.mutations.REMOVE_FEE]: (state) => {
    let isSavedItem = state.fees[state.removeFee.feeId] && state.fees[state.removeFee.feeId].isAdded ? false : true;

    if (state.fees[state.removeFee.feeId] && state.feeHistory[state.removeFee.feeId]) {
      delete state.feeHistory[state.removeFee.feeId];
      delete state.fees[state.removeFee.feeId];
    } else if (state.removeFee.isHistory) delete state.feeHistory[state.removeFee.feeId];
    else delete state.fees[state.removeFee.feeId];

    if (isSavedItem) {
      state.feesRemoved = [...state.feesRemoved, state.removeFee.feeId];
    }
  },

  [_types.mutations.SET_LOCKER_MODAL_ACTIVE]: (state, payload) => {
    state.isLockerModalActive = payload;
  },

  [_types.mutations.SET_LOCKER_SERIES]: (state, payload) => {
    let result = payload.reduce((obj, item) => {
      obj[item.id] = item;
      return obj;
    }, {});
    state.lockerSeries = result;
    if (payload.length > 0) {
      let sortedPayload = [...payload].sort((a, b) => {
        return a.name.localeCompare(b.name, 'sv-SE');
      });
      let firstLocker = sortedPayload.find((x) => x.articleTypeId === '9d4dd65b-2f84-494d-bf9f-3f11e29e8e1d');
      state.selectedLockerSeriesId = firstLocker ? firstLocker.id : payload[0].id;
    }
  },

  [_types.mutations.SET_LOCKERS]: (state, seriesIdlockers) => {
    state.lockerSeries[seriesIdlockers.id].lockers = seriesIdlockers.lockers;
  },

  [_types.mutations.SET_SELECTED_LOCKER_SERIES_ID]: (state, id) => {
    state.selectedLockerSeriesId = id;
    state.selectedLockers = {};
    state.filter = { onlyUnrented: true, numberFrom: null, numberTo: null };
  },

  [_types.mutations.SET_SELECTED_LOCKER]: (state, locker) => {
    if (state.selectedLockers[locker.id]) {
      delete state.selectedLockers[locker.id];
    } else {
      state.selectedLockers[locker.id] = locker;
    }
  },

  [_types.mutations.CLEAR_LOCKERS]: (state) => {
    state.selectedLockers = {};
    state.filter = { onlyUnrented: true, numberFrom: null, numberTo: null };
    state.hasNoLockers = false;
  },

  [_types.mutations.SET_HAS_NO_LOCKERS]: (state) => {
    state.hasNoLockers = true;
  },

  [_types.mutations.SET_FILTER_LOCKERS]: (state, payload) => {
    state.filter[payload.key] = payload.value;
  },

  [_types.mutations.SET_EDIT_LOCKER_ID]: (state, feeIdIsHistory) => {
    if (!feeIdIsHistory) {
      delete state.editLocker.feeId;
    }
    state.editLocker.feeId = feeIdIsHistory.feeId;
    state.editLocker.isHistory = feeIdIsHistory.isHistory;
  },

  [_types.mutations.EDIT_LOCKER]: (state, payload) => {
    if (!payload || !payload.field) {
      state.editLocker = {};
    } else {
      state.editLocker[payload.field] = payload.value;
    }
  },

  [_types.mutations.EDIT_FEE]: (state, payload) => {
    if (payload.isHistory) {
      state.feeHistory[payload.feeId][payload.field] = payload.value;
    } else {
      state.fees[payload.feeId][payload.field] = payload.value;
    }
  },

  [_types.mutations.SET_FEE_HISTORY]: (state, payload) => {
    if (!payload) {
      state.feeHistory = payload;
      state.sourceFeeHistory = payload;
    } else {
      let result = payload.reduce((obj, item) => {
        item.isFeeHistory = true;
        obj[item.id] = item;
        return obj;
      }, {});
      state.feeHistory = result;
      state.sourceFeeHistory = JSON.parse(JSON.stringify(result));
    }
  },

  [_types.mutations.SET_FEES_HASH]: (state) => {
    let fees = hashHelper.hashObject(state.fees);
    state.feesHash = fees;
    state.feesUpdatedHash = fees;
  },

  [_types.mutations.SET_FEE_HISTORY_HASH]: (state) => {
    let history = hashHelper.hashObject(state.feeHistory);
    state.feeHistoryHash = history;
    state.feeHistoryUpdatedHash = history;
  },

  [_types.mutations.SET_FEES_UPDATED_HASH]: (state, payload) => {
    state.feesUpdatedHash = payload;
  },

  [_types.mutations.SET_FEE_HISTORY_UPDATED_HASH]: (state, payload) => {
    state.feeHistoryUpdatedHash = payload;
  },

  [_types.mutations.CLEAR_FORM]: (state) => {
    state.feesRemoved = [];
    state.editLocker = {};
    state.returnToClub = false;
  },
  [_types.mutations.SET_DUE_DATE]: (state, fieldValue) => {
    state[fieldValue.field] = fieldValue.value;
  },
  [_types.mutations.INITIATE_ADD_ARTICLE]: (state) => {
    state.selectedDueDate = moment().add(20, 'days').format('YYYY-MM-DD');
  },

  //CAPITAL INVESTMENTS
  [_types.mutations.SET_CAPITAL_MODAL_ACTIVE]: (state, payload) => {
    const { isNested, isActive } = payload;
    if (isNested) {
      state.isNestedCapitalModalActive = isActive;
    } else {
      state.isCapitalModalActive = isActive;
    }
  },
  [_types.mutations.SET_CAPITAL_ARTICLES]: (state, capitalArticles) => {
    let result = capitalArticles.reduce((pv, cv) => {
      pv[cv.id] = cv;
      return pv;
    }, {});
    state.capitalArticles = result;
    if (capitalArticles.length > 0) {
      let sortedCapitalArticles = [...capitalArticles].sort((a, b) => {
        return a.name.localeCompare(b.name, 'sv-SE');
      });
      state.selectedCapitalArticleId = sortedCapitalArticles[0].id;
    }
  },
  [_types.mutations.SET_FIELD]: (state, fieldValue) => {
    const { field, value } = fieldValue;
    state[field] = value;
  },
  [_types.mutations.SET_CAPITAL_SERIES]: (state, payload) => {
    let { id, capitals } = payload;
    state.capitalArticles[id].capitals = capitals;
  },
  [_types.mutations.SET_CAPITAL_ARTICLE_TYPE]: (state, type) => {
    const { capitalArticles } = state;
    if (capitalArticles) {
      let filteredCapitalArticles = Object.values(capitalArticles).filter((o) => o.capitalInvestmentTypeId == type);
      filteredCapitalArticles = filteredCapitalArticles.sort((a, b) => a.name.localeCompare(b.name));
      if (filteredCapitalArticles.length > 0) {
        state.selectedCapitalArticleId = filteredCapitalArticles[0].id;
      } else {
        state.selectedCapitalArticleId = null;
      }
    }
    state.selectedCapitalArticleType = type;
  },
  [_types.mutations.SET_FILTER_CAPITAL_SERIES]: (state, filterObject) => {
    const { key, value } = filterObject;
    state.capitalFilter[key] = value;
  },
  [_types.mutations.SET_SELECTED_CAPITAL_SERIE]: (state, capitalSerie) => {
    const { id } = capitalSerie;
    const { selectedCapitalSeries } = state;
    if (selectedCapitalSeries[id]) {
      delete selectedCapitalSeries[id];
    } else {
      selectedCapitalSeries[id] = capitalSerie;
    }
  },
  [_types.mutations.CLEAR_CAPITAL_SERIES]: (state) => {
    state.selectedCapitalSeries = {};
    state.capitalFilter = { onlyForSale: false, numberFrom: null, numberTo: null };
  },
  [_types.mutations.SET_EDIT_CAPITAL_ID]: (state, feeIdIsHistory) => {
    if (!feeIdIsHistory) {
      delete state.editCapital.feeId;
    }
    state.editCapital.feeId = feeIdIsHistory.feeId;
    state.editCapital.isHistory = feeIdIsHistory.isHistory;
  },
  [_types.mutations.CLEAR_EDIT_CAPITAL]: (state) => {
    state.capitalHash = undefined;
    state.selectedCurrentOwnerType = undefined;
    state.isCurrentUserChanged = false;
  },
  [_types.mutations.EDIT_CAPITAL]: (state, payload) => {
    if (!payload || !payload.field) {
      state.editCapital = {};
    } else {
      state.editCapital[payload.field] = payload.value;
    }
  },
  [_types.mutations.SET_SEARCH_MODAL_ACTIVE]: (state, payload) => {
    const { isActive, isCurrentOwner } = payload;
    if (isCurrentOwner) {
      state.isCurrentOwner = true;
    } else {
      state.isCurrentOwner = false;
    }

    state.isNestedSearchModalActive = isActive;
  },

  [_types.mutations.SET_CURRENT_OWNER_DATE]: (state, datepickerValue) => {
    const { editCapital, fees } = state;
    fees[editCapital.feeId].currentOwner.created = datepickerValue;
  },

  [_types.mutations.SET_SEARCH_RESULT]: (state, result) => {
    state.partnersSearchResult = result;
  },
  [_types.mutations.SEARCH_CLEANUP]: (state) => {
    state.partnersSearchResult = undefined;
    state.searchPartner = '';
    state.selectedSearchType = 'person';
  },

  [_types.mutations.SET_ORIGINAL_CREATED_DATE]: (state, payload) => {
    const result = state.fees[payload.feeId].currentOwner.created;
    state.originalCreatedDate = result;
  },

  [_types.mutations.SET_CHANGED_DATE_TO_ORIGINAL]: (state) => {
    const { fees, editCapital, originalCreatedDate } = state;
    fees[editCapital.feeId].currentOwner.created = originalCreatedDate;
  },

  [_types.mutations.SET_CAPITAL_HASH]: (state) => {
    const { feeId } = state.editCapital;
    let stateToHash = hashHelper.hashObject([state.returnToClub, state.selectedCurrentOwnerType, state.isCurrentUserChanged, state.fees[feeId]]);
    state.capitalHash = stateToHash;
  },

  //////////////////////////

  [_types.mutations.CLEANUP]: (state) => {
    delete state.fees;
    state.isCapitalModalActive = false;
    state.isLockerModalActive = false;
    state.isFeeModalActive = false;
    state.isNestedCapitalModalActive = false;
    state.isNestedSearchModalActive = false;
    state.isCurrentOwner = false;
    state.returnToClub = false;
    state.selectedLockers = {};
    state.selectedCapitalSeries = {};
    state.editLocker = {};
    state.editCapital = {};
    state.feesRemoved = [];
    state.filter = {
      onlyUnrented: false,
      numberFrom: null,
      numberTo: null,
    };
    state.capitalFilter = {
      onlyForSale: false,
      numberFrom: null,
      numberTo: null,
    };

    state.selectedDueDate = moment().add(20, 'days').format('YYYY-MM-DD');

    delete state.removeFee;
    delete state.selectedLockerSeriesId;
    delete state.selectedCapitalArticleId;
    delete state.selectedCapitalSerieId;
    delete state.lockerSeries;
    delete state.capitalArticles;
    delete state.selectedCapitalArticleType;
    delete state.selectedSearchType;
    delete state.feeHistory;
    delete state.sourceFeeHistory;
    delete state.feesHash;
    delete state.feesUpdatedHash;
    delete state.feeHistoryHash;
    delete state.feeHistoryUpdatedHash;
    delete state.partnersSearchResult;
  },
};
