import moment from 'moment';
import { hashHelper } from '@sgf/go-utilities';

class ValidationMessage {
  level = 'error';
  constructor(field, msg, level) {
    this.field = field;
    this.message = msg;
    if (level) {
      this.level = level;
    }
  }
}
function handleNoteLength(player, result) {
  if (player.note == undefined || player.note == '') {
    return;
  }
  if (player.note.length > 1000) {
    result.push(new ValidationMessage('note', 'Notering får ej vara mer än 1000 tecken'));
  }
}

function handleGolfCarPermission(clubDatesHash, sgfDatesHash, golfCarPermission, result) {
  if (!golfCarPermission) {
    return;
  }
  let clubDateFrom = new Date(golfCarPermission.clubValidFrom);
  let clubDateTo = new Date(golfCarPermission.clubValidTo);
  let sgfDateFrom = new Date(golfCarPermission.sgfValidFrom);
  let sgfDateTo = new Date(golfCarPermission.sgfValidTo);
  let clubDatesHasChanges = clubDatesHash !== hashHelper.hashObject(golfCarPermission.clubValidFrom, golfCarPermission.clubValidTo);
  let sgfDatesHasChanges = sgfDatesHash !== hashHelper.hashObject(golfCarPermission.sgfValidFrom, golfCarPermission.sgfValidTo);

  if (golfCarPermission.clubValidFrom && golfCarPermission.clubValidTo && clubDatesHasChanges) {
    let valClubFromDate = isGolfCarDateValid('clubValidFrom', golfCarPermission.clubValidFrom, 'Från datum (klubbtävling)');
    let valClubToDate = isGolfCarDateValid('clubValidTo', golfCarPermission.clubValidTo, 'Till datum (klubbtävling)');

    if (!valClubFromDate.isValid) {
      result.push(valClubFromDate.message);
    } else if (!valClubToDate.isValid) {
      result.push(valClubToDate.message);
    } else if (clubDateFrom > clubDateTo) {
      result.push(new ValidationMessage('clubValidFrom', 'Från datum kan ej vara större än till datum.'));
    }
  } else if (golfCarPermission.clubValidFrom && !golfCarPermission.clubValidTo) {
    result.push(new ValidationMessage('clubValidTo', 'Till datum (klubbtävling) saknas'));
  } else if (!golfCarPermission.clubValidFrom && golfCarPermission.clubValidTo) {
    result.push(new ValidationMessage('clubValidFrom', 'Från datum (klubbtävling) saknas'));
  }

  if (golfCarPermission.sgfValidFrom && golfCarPermission.sgfValidTo && sgfDatesHasChanges) {
    let valSgfFromDate = isGolfCarDateValid('sgfValidFrom', golfCarPermission.sgfValidFrom, 'Från datum (förbundstävling)');
    let valSgfToDate = isGolfCarDateValid('sgfValidTo', golfCarPermission.sgfValidTo, 'Till datum (förbundstävling)');

    if (!valSgfFromDate.isValid) {
      result.push(valSgfFromDate.message);
    } else if (!valSgfToDate.isValid) {
      result.push(valSgfToDate.message);
    } else if (sgfDateFrom > sgfDateTo) {
      result.push(new ValidationMessage('sgfValidFrom', 'Från datum kan ej vara större än till datum.'));
    }
  } else if (golfCarPermission.sgfValidFrom && !golfCarPermission.sgfValidTo) {
    result.push(new ValidationMessage('sgfValidTo', 'Till datum (förbundstävling) saknas'));
  } else if (!golfCarPermission.sgfValidFrom && golfCarPermission.sgfValidTo) {
    result.push(new ValidationMessage('sgfValidFrom', 'Från datum (förbundstävling) saknas'));
  }
}

function isGolfCarDateValid(field, date, validationString) {
  let validation = {
    isValid: true,
  };
  let passedDate = new Date(date);
  let floorDate = new Date();
  floorDate.setFullYear(floorDate.getFullYear() - 2);
  let ceilingDate = new Date();
  ceilingDate.setFullYear(floorDate.getFullYear() + 5);

  if (isNaN(passedDate) || date.length != 10) {
    validation.isValid = false;
    validation.message = new ValidationMessage(field, validationString + ' är i felaktigt format.');
  } else if (passedDate > ceilingDate) {
    validation.isValid = false;
    validation.message = new ValidationMessage(field, `Datum måste vara lägre än ${moment(ceilingDate).format('YYYY-MM-DD')}`);
  } else if (passedDate < floorDate) {
    validation.isValid = false;
    validation.message = new ValidationMessage(field, `Datum måste vara högre än ${moment(floorDate).format('YYYY-MM-DD')}`);
  }

  return validation;
}

export default {
  validate(state) {
    let result = [];
    handleNoteLength(state.player, result);
    handleGolfCarPermission(state.player.clubDatesHash, state.player.sgfDatesHash, state.player.golfCarPermission, result);

    return result;
  },
};
