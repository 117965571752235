import { createStore } from 'vuex';
import globalevents from './globalevents';
import authenticationStore, { authenticationTypes } from './authentication';
import { apiStore, apiTypes } from '@sgf/go-api-services';
import { searchStore, searchTypes } from '@sgf/go-person-search';
import { personOverviewStore, personOverviewTypes } from '@sgf/go-person-overview';
import { personInformationTypes } from '@sgf/go-person-information';
import personInformationStore from './personInformationStore';
import { familyGroupStore, familyGroupTypes } from '@sgf/go-person-familygroup';
import { queueStore, queueTypes } from '@sgf/go-person-queue';
import { feeLockerStore } from '../modules/feeLocker';
import { searchArticleStore } from '@sgf/go-article';
import { personMembershipStore, personMembershipTypes } from '@sgf/go-person-membership';
import { personDetailsStore, personDetailsTypes } from '../modules/personDetails';
import miscellaneousStore from '../modules/miscellaneous/store';
import feeDetailsStore from '../modules/feeDetails/store';
import invoiceStore from '../modules/invoice/store';

apiStore.state.baseUrl = process.env.VUE_APP_APIENDPOINT;

const store = createStore({
  modules: {
    [globalevents.namespace]: globalevents,
    [authenticationTypes.namespace]: authenticationStore,
    [apiTypes.namespace]: apiStore,
    [searchTypes.namespace]: searchStore,
    [personOverviewTypes.namespace]: personOverviewStore,
    [personInformationTypes.namespace]: personInformationStore,
    [familyGroupTypes.namespace]: familyGroupStore,
    [queueTypes.namespace]: queueStore,
    [feeLockerStore.namespace]: feeLockerStore,
    [searchArticleStore.namespace]: searchArticleStore,
    [personMembershipTypes.namespace]: personMembershipStore,
    [personDetailsTypes.namespace]: personDetailsStore,
    [invoiceStore.namespace]: invoiceStore,
    [miscellaneousStore.namespace]: miscellaneousStore,
    [feeDetailsStore.namespace]: feeDetailsStore,
  },
});

export default store;
