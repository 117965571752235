export default {
  getPayload(state, selectedFee) {
    let selectedArticle = state.roundToRemove.availableFees.find((x) => x.articleId === selectedFee);
    let round = state.usageOccasions.find((x) => x.number === state.selectedRound);
    return {
      chosenFee: { ...selectedArticle },
      round: {
        ...round,
        course: {
          id: round.courseId,
          name: round.courseName,
        },
      },
      player: {
        ...state.roundToRemove.player,
      },
    };
  },
};
