import { personInformationGetters, personInformationMutations, personInformationState } from '@sgf/go-person-information';
import actions from './actions';

export default {
  namespaced: true,
  actions: actions,
  getters: personInformationGetters,
  mutations: personInformationMutations,
  state: personInformationState,
};
